import styled from '@emotion/styled'
import { Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Includes } from './Includes'

export interface Props {
  contactsPageURL?: string
  cta?: ButtonProps
  description?: string
  includes?: string
  languageCode: string
  subtitle?: string
  terms?: string
  title?: string
}

export const OfferIntro = memo(function OfferIntro({
  contactsPageURL,
  cta,
  description,
  includes,
  languageCode,
  subtitle,
  terms,
  title,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}

        {terms ? (
          <Wrapper>
            <Label>{useVocabularyData('terms-conditions', languageCode)}</Label>
            <Terms dangerouslySetInnerHTML={{ __html: terms }} />
          </Wrapper>
        ) : null}
      </LeftSide>
      <RightSide>
        <Includes
          contactsPageURL={contactsPageURL}
          cta={cta}
          includes={includes}
          languageCode={languageCode}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 5.375rem 7.431vw 0 12.639vw;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3.75rem 1.25rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  margin-top: 0.75rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin-top: 1.75rem;
`

const Wrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-top: 3.375rem;
  padding-top: 3.75rem;

  @media (max-width: 767px) {
    margin-top: 1.875rem;
    padding-top: 2rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Terms = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.625rem;
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 8.25rem 12.639vw 6.375rem 8.472vw;

  @media (max-width: 1023px) {
    padding: 6.25rem 1.25rem 5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 3rem 1.25rem;
  }
`
